import { makeAutoObservable, runInAction } from 'mobx'
import { createContext, useContext } from 'react'

import { getCourseData } from './api'

class DataStore {
  courses = []
  userCourses = []
  selectedCourse = {}
  user = null
  userAuthenticated = !!window.localStorage.getItem('token')

  constructor() {
    makeAutoObservable(this)
  }

  async loadCourseList(force = false) {
    if (force || (!this.courses.length && !this.userCourses.length)) {
      try {
        const data = await getCourseData()
        runInAction(() => {
          this.courses = data.rest_courses
          this.userCourses = data.user_courses
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  setSelectedCourse(courseData) {
    this.selectedCourse = courseData
  }

  setAuth(bool) {
    this.userAuthenticated = bool
  }

  setUser(user) {
    this.user = user
  }
}

const StoreContext = createContext(new DataStore())

const StoreProvider = ({ store, children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  )
}

const useStore = () => {
  return useContext(StoreContext);
}

export { DataStore, StoreProvider, useStore }
