import { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../images/orange-play.svg'

import { StyledDuration } from './StyledBlocks'
import Modal from './Modal'
import VideoPlayer from './VideoPlayer'
import { toJS } from 'mobx'

const VideoListContainer = styled.div`
  margin-top: 40px;

  h2 {
    font-size: 18px;
    color: $durationColor;
    font-weight: 400;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $durationColor;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
`

const PlayIcon = styled.div`
  display: inline-block;
  margin-right: 10px;
  display: inline-flex;
`

const VideoListHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const Duration = styled(StyledDuration)`
  text-shadow: none;
`

export default function VideoList({videos = [], duration = ''}) {
  const [selectedVideoUrl, setSelected] = useState('')
  const [selectedVideoTitle, setSelectedVideoTitle] = useState('')
  const [showVideo, setShowVideo] = useState(false)

  const handleVideoClick = (video) => {
    console.log(video)
    if (video.video_link) {
      setSelected(video.video_link)
      setSelectedVideoTitle(video.title)
      setShowVideo(true)
    }
  }

  return (
    <VideoListContainer>
      <VideoListHeader>
        <h2>Курстун мазмуну</h2>
        <Duration>{duration}</Duration>
      </VideoListHeader>
      <ul>
        {videos.map(video => (
          <li key={video.title} onClick={() => handleVideoClick(toJS(video))}>
            <PlayIcon>
              <Icon
                height={16}
                width={16}
              />
            </PlayIcon>
            {video.title}
          </li>
        ))}
      </ul>
      {(showVideo && selectedVideoUrl) && (
        <Modal onClose={() => setShowVideo(false)}>
          <VideoPlayer
            url={selectedVideoUrl}
            title={selectedVideoTitle}
          />
        </Modal>
      )}
    </VideoListContainer>
  )
}
