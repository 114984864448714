import React from 'react'
import Plyr from 'plyr-react'
import styled from 'styled-components'

import 'plyr-react/dist/plyr.css'

const Text = styled.p`
  color: white;
`

export default function VideoPlayer({url, title}) {
  if (!url) {
    return <Text>Видео жеткиликсиз</Text>
  }

  const videoSrc = {
    type: 'video',
    title,
    sources: [
      {
        src: url,
        provider: "youtube"
      }
    ]
  }

  return (
    <Plyr source={videoSrc} />
  )
}
