import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import { getUserToken, TOKEN_KEY } from '../store/api'
import { useStore } from '../store/data';

import Modal from './Modal'
import LoginForm from './LoginForm'
import { ReactComponent as Logo } from '../images/logo.svg'
import { ReactComponent as UserIcon } from '../images/user-icon.svg'
import { ReactComponent as Logout } from '../images/logout.svg'

import { Container, globals } from './StyledBlocks'

const HeaderContainer = styled.div`
  height: 56px;
  border-bottom: 1px solid ${globals.line};
`

const InnerContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const StyledLink = styled(Link)`
  display: inline-flex;
`

const Icon = styled.div`
  display: flex;
  cursor: pointer;
`

const Header = observer(() => {
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState('')
  const [showLogin, setShowLogin] = useState(false)
  const store = useStore()
  const history = useHistory()

  const fetchCourses = async () => {
    await store.loadCourseList(true)
  }

  const handleLogin = async (formData) => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setShowError('')
    const data = await getUserToken(formData)
    setIsLoading(false)

    if (data && data.key) {
      window.localStorage.setItem(TOKEN_KEY, data.key)
      store.setAuth(true)

      setShowLogin(false)
      fetchCourses()
    } else {
      setShowError('Ошибка авторизации')
    }
  }

  const handleLogout = () => {
    window.localStorage.removeItem(TOKEN_KEY)
    store.setAuth(false)
    store.setUser(null)

    fetchCourses()
    history.push('/')
  }

  return (
    <HeaderContainer>
      <InnerContainer>
        <StyledLink to="/">
          <Logo />
        </StyledLink>
        {store.userAuthenticated ? (
          <Icon onClick={handleLogout}>
            <Logout />
          </Icon>
        ) : (
          <Icon onClick={() => setShowLogin(true)}>
            <UserIcon />
          </Icon>
        )}
      </InnerContainer>

      {showLogin && (
        <Modal onClose={() => setShowLogin(false)}>
          <LoginForm
            isLoading={isLoading}
            showError={showError}
            onLogin={handleLogin}
          />
        </Modal>
      )}
    </HeaderContainer>
  )
})

export default Header
