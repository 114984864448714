import styled from 'styled-components'
import { globals} from './StyledBlocks'
import { ReactComponent as PlayIcon } from '../images/play.svg'

const PreviewContainer = styled.div`
  height: 220px;
  background-size: cover;
  background-color: ${globals.cardBg};
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -22px;

  @media screen and (min-width: 36rem) {
    margin-top: 0;
  }
`

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  cursor: pointer;
`

const PreviewText = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${globals.white};
  font-size: 18px;
  line-height: 24px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`

export default function Preview({ preview_image_url, onPreviewClick }) {
  return (
    <PreviewContainer
      style={{backgroundImage: `url(${preview_image_url})`}}
    >
      <PlayButton
        onClick={onPreviewClick}
      >
        <PlayIcon
          height={60}
          width={60}
        />
      </PlayButton>
      <PreviewText>
        Таанытым видео
      </PreviewText>
    </PreviewContainer>
  )
}
