import styled from 'styled-components'

export const globals = {
  orange: '#EB6422',
  white: '#fff',
  black: '#000',
  grey: 'grey',
  titleColor: '#39373A',
  durationColor: '#39373A',
  line: '#ebebeb',
  cardBg: '#e0e0e0',
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
}

export const Container = styled.div`
  max-width: 36rem;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`

export const StyledTitle = styled.div`
  color: ${globals.titleColor};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 26px;
  font-weight: 600;
`

export const StyledDuration = styled.div`
  color: ${globals.durationColor};
  font-size: 12px;
  line-height: 14px;
`
