import axios from 'axios';

const BASE_URL = 'https://api.jizn.today/'
// const BASE_URL = 'http://localhost:4200/'
const API = axios.create({baseURL: BASE_URL});
export const TOKEN_KEY = 'token'

API.interceptors.response.use(response => response, error => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  if (error.response.status === 401 && token) {
    window.localStorage.removeItem(TOKEN_KEY)
  }
})


function getAuthHeader() {
  const headers = {}
  const userToken = window.localStorage.getItem(TOKEN_KEY)
  if (userToken) {
    headers['Authorization'] = `Token ${userToken}`
  }

  return headers
}

export async function getCourseData() {
  const headers = getAuthHeader()
  const res = await API.get(`/api/v1/courses/`, { headers })
  if (res.status === 200) {
    return res.data
  } else {
    return
  }
}

export async function getCourseDetails(course_id) {
  const headers = getAuthHeader()
  try {
    const res = await API.get(`/api/v1/courses/${course_id}`, { headers })
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export async function getUserToken(formData) {
  try {
    const res = await API.post(`/api/v1/auth/login/`, formData)
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export async function getUser() {
  const headers = getAuthHeader()
  try {
    const res = await API.get(`/api/v1/auth/user/`, { headers })
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}

export async function refreshToken(token) {
  const headers = getAuthHeader()
  try {
    const res = await API.post(`/api/v1/auth/token/refresh/`, {
      token
    }, { headers })
    return res.data
  } catch (error) {
    console.error(error)
    return
  }
}
