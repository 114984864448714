import styled from 'styled-components'

import Header from './Header'
import { Container } from './StyledBlocks'

const Main = styled(Container)`
  padding: 20px;
  padding-bottom: 100px;
`

export default function Layout({ children, home }) {
  return (
    <div>
      <Header />
      <Main>{children}</Main>
    </div>
  )
}
