import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import Layout from './components/Layout'
import Home from './pages/Home'
import CourseDetails from './pages/CourseDetails'
import { DataStore, StoreProvider } from './store/data'
import { getUser, TOKEN_KEY } from './store/api';

const store = new DataStore()

function App() {
  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN_KEY)
    if (token) {
      getUser().then(userData => {
        store.setUser(userData)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [])

  return (
    <StoreProvider store={store}>
      <Router>
        <Layout>
            <Switch>
              <Route path="/course/:courseId">
                <CourseDetails />
              </Route>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
        </Layout>
      </Router>
    </StoreProvider>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;
