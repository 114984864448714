import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Card from '../components/Card'
import { globals } from '../components/StyledBlocks'
import { useStore } from '../store/data'

const Hero = styled.h1`
  font-size: 32px;
  color: ${globals.orange};
  margin-top: 66px;
  font-weight: 300;
`

const Heading = styled.h3`
  font-size: 22px;
  color: ${globals.black};
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: ${props => props.marginTop ? '2rem' : '1rem'};
`

const Home = observer(() => {
  let history = useHistory()
  const store = useStore()

  useEffect(() => {
    async function fetchCourses() {
      await store.loadCourseList()
    }

    fetchCourses()
  }, [store])

  let heroText = 'Келгиле, окуйбуз!'
  if (store.userAuthenticated && store.user?.first_name) {
    heroText = `Салам, ${store.user.first_name}!`
  }

  return (
    <section>
      <Hero>
        {heroText}
      </Hero>
      {!!store.userCourses.length && (
        <>
          <Heading>Менин курстарым</Heading>
          {store.userCourses.map(course => (
            <Card
              key={course.id}
              title={course.title}
              preview_image_url={course.preview_image_url}
              duration={course.duration}
              onCardClick={() => history.push(`/course/${course.id}`)}
              price={course.price}
              old_price={course.old_price}
            />
          ))}
        </>
      )}
      
      <Heading marginTop>Көп сатылгандар</Heading>
      {store.courses.map(course => (
        <Card
          key={course.id}
          title={course.title}
          preview_image_url={course.preview_image_url}
          duration={course.duration}
          onCardClick={() => history.push(`/course/${course.id}`)}
          price={course.price}
          old_price={course.old_price}
        />
      ))}
      
    </section>
  )
})

export default Home
