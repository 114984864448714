import styled from 'styled-components'
import { globals } from './StyledBlocks'

const Button = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background: ${globals.white};
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -4px 15px rgb(0 0 0 / 5%);
`

const Link = styled.a`
  font-size: 16px;
  background: ${globals.orange};
  display: block;
  width: 100%;
  text-align: center;
  color: ${globals.white};
  line-height: 40px;
  border-radius: 10px;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  @media screen and (min-width: 36rem) {
    width: 16rem;
  }
`

export default function BuyButton({courseTitle}) {
  return (
    <Button>
      <Link href={`https://wa.me/+996557990540?text=Ассалом алейкум! "${courseTitle}" курсун алгым келет. Кантип төлөм жүргүзөм?`} target="_blank" rel="noopener noreferrer">Сатып ал</Link>
    </Button>
  )
}
