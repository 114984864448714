import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../images/close.svg'

const ModalBody = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 85%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;
`

const ModalClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  cursor: pointer;
  width: 36px;
  height: 36px;

  svg {
    width: 36px;
    height: 36px;
  }
`


export default function Modal({ children, onClose }) {
  return (
    <ModalBody>
      <ModalClose
        onClick={onClose}
      >
        <CloseIcon />
      </ModalClose>
      {children}
    </ModalBody>
  )
}
