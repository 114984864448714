import styled from 'styled-components'
import { StyledTitle, StyledDuration } from './StyledBlocks'

const Title = styled.h1(StyledTitle)

const Duration = styled(StyledDuration)`
  text-shadow: none;
  margin-bottom: 16px;
`

const PriceBlock = styled.div`
  display: flex;
  align-items: baseline;
`

const Price = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: $orange;
  margin-right: 10px;
`

const OldPrice = styled.div`
  font-size: 16px;
  text-decoration: line-through;
  color: $grey;
`
const Description = styled.div`
  margin-top: 40px;

  h2 {
    font-size: 18px;
    color: $durationColor;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    color: $durationColor;
  }
`

export default function CourseText({ title, description, duration, price, old_price }) {
  return (
    <>
    <div>
      <Title>{title}</Title>
      <Duration>{duration}</Duration>
      <PriceBlock>
        <Price>{price}</Price>
        {old_price && <OldPrice>{old_price}</OldPrice>}
      </PriceBlock>
    </div>

    <Description>
      <h2>Курс жөнүндө</h2>
      <p>{description}</p>
    </Description>
    </>
  )
}
