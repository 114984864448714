import styled from 'styled-components'
import { globals, StyledTitle, StyledDuration } from './StyledBlocks'

const CardBody = styled.div`
  border-radius: 10px;
  height: 12rem;
  padding: 20px;
  background-color: ${globals.cardBg};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  background-size: cover;

  & + & {
    margin-top: 36px;
  }

  @media screen and (min-width: 36rem) {
    height: 16rem;
  }
`

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Title = styled(StyledTitle)`
  text-shadow: ${globals.textShadow};
  color: ${globals.white};
`

const Duration = styled(StyledDuration)`
  text-shadow: ${globals.textShadow};
  color: ${globals.white};
`

const Price = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${globals.orange};
  text-shadow: ${globals.textShadow};
`

const OldPrice = styled.div`
  font-size: 16px;
  text-decoration: line-through;
  color: ${globals.white};
  text-shadow: ${globals.textShadow};
`

const CardFooterR = styled.div`
  margin-left: 10px;
  text-align: right;
  width: 50%;
`


export default function Card({title, preview_image_url, duration, price, old_price, onCardClick}) {
  return (
    <CardBody
      style={{backgroundImage: `url(${preview_image_url})`}}
      onClick={onCardClick}
    >
      <CardFooter>
        <div>
          <Title>{title}</Title>
          <Duration>{duration}</Duration>
        </div>
        <CardFooterR>
          <Price>
            {price}
          </Price>
          {old_price && <OldPrice>
            {old_price}
          </OldPrice>}
        </CardFooterR>
      </CardFooter>
    </CardBody>
  )
}
