
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useParams } from 'react-router-dom'
import { toJS } from 'mobx'

import { useStore } from '../store/data'
import Preview from '../components/Preview'
import CourseTitle from '../components/CourseText'
import VideoList from '../components/VideoList'
import BuyButton from '../components/BuyButton'
import VideoPlayer from '../components/VideoPlayer'
import Modal from '../components/Modal'
import { getCourseDetails } from '../store/api'


const Course = observer(() => {
  const history = useHistory()
  const params = useParams()
  const store = useStore()
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    // Look for existing course in the store
    const getCourse = (courseId) => {
      const allCourses = [...toJS(store.courses), ...toJS(store.userCourses)]
      const course = allCourses.find((course) => course.id === +courseId)
  
      if (course) {
        store.setSelectedCourse(course)
      } else {
        loadCourseDetails(courseId)
      }
    }

    // Load course details if not in store
    const loadCourseDetails = async (courseId) => {
      const data = await getCourseDetails(courseId)
      if (data) {
        store.setSelectedCourse(data)
      } else {
        history.push('/404')
      }
    }

    if (params.courseId) {
      getCourse(params.courseId)
    }
  }, [store, params.courseId, history])

  return (
    <>
      <section>
        <Preview
          preview_image_url={store.selectedCourse.preview_image_url}
          onPreviewClick={() => setShowVideo(true)}
        />
        <CourseTitle
          title={store.selectedCourse.title}
          description={store.selectedCourse.description}
          duration={store.selectedCourse.duration}
          price={store.selectedCourse.price}
          old_price={store.selectedCourse.old_price}
        />
        <VideoList
          videos={store.selectedCourse.videos}
          duration={store.selectedCourse.duration}
        />
      </section>
      {store.selectedCourse.price && <BuyButton
        courseTitle={store.selectedCourse.title}
      />}
      {showVideo && (
        <Modal onClose={() => setShowVideo(false)}>
          <VideoPlayer
            url={store.selectedCourse.preview_video}
            title={store.selectedCourse.title}
          />
        </Modal>
      )}
    </>
  )
})

export default Course

