import { useState } from 'react';
import styled from 'styled-components';
import { globals } from './StyledBlocks';


const LoginPage = styled.div`
  background-color: ${globals.white};
  border-radius: 10px;
  padding: 20px;
  width: 70%;
  max-width: 16rem;
`

const FormGroup = styled.div`
  margin-bottom: 10px;
`

const Label = styled.label`
  display: inline-block;
  margin-bottom: .5rem;
  font-size: 12px;
  color: $grey;
`

const Input = styled.input`
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: $orange;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(235 100 34 / 25%);
  }
`

const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
  text-align: center;
`

const Button = styled.button`
  font-size: 16px;
  margin-top: 16px;
  display: block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  border: 1px solid transparent;
  padding: .300rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  background-color: ${globals.orange};
  border-color: ${globals.orange};
  width: 100%;
`


export default function LoginForm({ onLogin, isLoading, showError }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username && password) {
      onLogin({
        username,
        password
      })
    }
  }

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  return (
    <LoginPage>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="username">Телефон номериниз</Label>
          <Input
            type="number"
            id="username"
            aria-describedby="username"
            placeholder="Телефон номериниз"
            value={username}
            onChange={handleUsernameChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Пароль</Label>
          <Input
            type="password"
            id="password"
            placeholder="Пароль"
            value={password}
            onChange={handlePasswordChange}
          />
        </FormGroup>
        {showError && <ErrorMessage>{showError}</ErrorMessage>}
        <Button type="submit">Кирүү</Button>
      </form>
    </LoginPage>
  )
}
